import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


import Taxreturn from './component/transactions/taxreturn';

export default function BasicExample() {
  return (
    <Router>
      <div>
       

        <Switch>
          <Route exact path="/" component={Taxreturn}/>
            
          
        </Switch>
      </div>
    </Router>
  );
}

// You can think of these components as "pages"
// in your app.

function Home() {
  return (
    <div>
      <h2>Home</h2>
    </div>
  );
}

function About() {
  return (
    <div>
      <h2>About</h2>
    </div>
  );
}


