import React from 'react';

function ApiResponse({ data }) {
  return (
    <pre style={{ height: '500px', width: '500px' }}>
      {JSON.stringify(data, null, 2)}
    </pre>
  );
}

export default ApiResponse;
